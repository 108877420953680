import $ from "jquery";
import "../../../scripts/library/auto-height.jquery.js";
import { setupQuantityControl, setupAddToCart } from "./product-utilities";
import "select2";

import "swiper/scss";
import "swiper/scss/navigation";

const { default: Swiper } = await import("swiper");
const { Navigation } = await import("swiper/modules");

setupQuantityControl();
setupAddToCart();

$("textarea").autoHeight();
$("select").select2({
  theme: "form",
  minimumResultsForSearch: -1,
  width: "100%",
});

$(".product__slider--type-1").each(function () {
  const swiper = new Swiper($(this).find(".swiper")[0], {
    modules: [Navigation],
    createElements: true,
    slidesPerView: 1.125,
    spaceBetween: 20,
    navigation: {
      prevEl: $(this).find(".product__slider--prev")[0],
      nextEl: $(this).find(".product__slider--next")[0],
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
    },
  });
});

$(".product__slider--type-2").each(function () {
  const swiper = new Swiper($(this).find(".swiper")[0], {
    modules: [Navigation],
    createElements: true,
    slidesPerView: 1.125,
    spaceBetween: 1,
    navigation: {
      prevEl: $(this).find(".product__slider--prev")[0],
      nextEl: $(this).find(".product__slider--next")[0],
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
    },
  });
});
