import $ from "jquery";

export const setupQuantityControl = () => {
  const minimumQuantity = parseInt($(".js-product-quantity").data("minimum"), 10);

  const updateQuantity = (delta) => {
    const quantityInput = $("#quantity");
    let currentQuantity = parseInt(quantityInput.val(), 10) || minimumQuantity;
    currentQuantity = Math.max(minimumQuantity, currentQuantity + delta);
    quantityInput.val(currentQuantity);
  };

  $(".js-quantity-decrease").on("click", () => updateQuantity(-1));
  $(".js-quantity-increase").on("click", () => updateQuantity(1));
};

export function setupAddToCart() {
  const $buttonCart = $("#button-cart");
  const $cartButton = $("#cart > button");
  const $cartUl = $("#cart-ul");
  const $jsMessageAddToCart = $(".js-message__add-to-cart");

  $buttonCart.on("click", () => {
    const $inputs = $(".js-product").find(
      'input[type="text"], input[type="hidden"], input[type="radio"]:checked, input[type="checkbox"]:checked, select, textarea',
    );

    const formDataArray = $inputs.serializeArray();

    const formData = {};
    $.each(formDataArray, (index, field) => {
      formData[field.name] = field.value;
    });

    // console.log("Form Data:", formData);
    // $inputs.each(function () {
    //   if (!formData[this.name]) {
    //     console.warn(`Missing option: ${this.name}`);
    //   }
    // });

    $.ajax({
      url: "index.php?route=checkout/cart/add",
      type: "POST",
      data: formData,
      dataType: "json",
      beforeSend: () => $buttonCart.button("loading"),
      complete: () => $buttonCart.button("reset"),
      success: ({ error, success, total, redirect }) => {
        $(".alert-dismissible, .invalid-feedback").remove();
        $(".form-control").removeClass("is-invalid");
        $(".form-check-input").removeClass("is-invalid");

        if (error) {
          if (error.option) {
            Object.keys(error.option).forEach((i) => {
              const $element = $(`#option${i}`);
              const errorMsg = `<div class="invalid-feedback">${error.option[i]}</div>`;

              if ($element.parent().hasClass("input-group")) {
                $element.parent().after(errorMsg);
              } else {
                $element.parent().append(errorMsg);
                $element.addClass("is-invalid");
              }
            });
          }

          if (error.recurring) {
            $('select[name="recurring_id"]').after(
              `<div class="text-danger">${error.recurring}</div>`,
            );
          }

          $(".text-danger").parent().addClass("has-error");
        }

        if (success) {
          $jsMessageAddToCart.html(
            `<div class="alert alert-success alert-dismissible" role="alert">${success}<button type="button" class="btn-close" data-bs-dismiss="alert"></button></div>`,
          );
          $cartButton.html(
            `<span class="icon-cart"></span>&nbsp;<span id="cart-total" class="cart__btn__total">${total}</span>`,
          );
          $("html, body").animate({ scrollTop: 0 }, "slow");
          $cartUl.load("index.php?route=common/cart/info&type=info");

          if (redirect) {
            window.location.href = redirect;
          }
        }
      },
      error: (xhr, ajaxOptions, thrownError) => {
        alert(`${thrownError}\r\n${xhr.statusText}\r\n${xhr.responseText}`);
      },
    });
  });
}

export function setupRecurringDescription() {
  $('select[name="recurring_id"], input[name="quantity"]').change(function () {
    $.ajax({
      url: "index.php?route=product/product/getRecurringDescription",
      type: "post",
      data: $('input[name="product_id"], input[name="quantity"], select[name="recurring_id"]'),
      dataType: "json",
      beforeSend: () => $("#help-recurring_id").html(""),
      success: (json) => {
        $(".alert-dismissible, .text-danger").remove();

        if (json.success) {
          $("#help-recurring_id").html(json.success);
        }
      },
    });
  });
}
